/*IMPORTS*/
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@700&family=Red+Hat+Text&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
/** FRONT PAGE **/
.App {
  color: white;
  display: flex;
  flex-direction: column;
  position:relative;
  width: 100vw;
  height:100vh;
  background-color: #0a0919;
}
.Body{
  color: white;
  display: flex;
  padding-bottom: 2vh;
  background-color: #0a0919;
}
.Card-style{
  color:rgba(117, 72, 104, 0.486);
  padding-top:1rem;
  padding-bottom:1rem;
  margin:2rem;
  font-family:"Red Hat Text, sans-serif";
}

.App-logo {
  margin-top: 10vh;
  height: 15vi;
  pointer-events: none;
  filter: drop-shadow(10px 10px #4b064a);
}

@keyframes App-logo-spin {
  25% {
    filter:drop-shadow(10px -10px #4b064a);
  }
  50% {
    filter:drop-shadow(-10px -10px #4b064a);
  }
  75% {
    filter:drop-shadow(-10px 10px #4b064a);
  }
  100% {
    transform: rotate(360deg);
   
  }
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo{
    animation: App-logo-spin infinite 20s linear;
    
  }
}
.NavBar{
  top:0;
  right: 0;
  float: right;
  padding-right: 3vw;
  position: absolute;
}
.NavBar a{
  font-size: calc(1em + 1vmin);
  color: white;
  font-weight:bolder;
  text-decoration: none;
  font-family: 'Red Hat Text';
  list-style-type: none;
  margin: 0;
  text-align: center;
  padding: 3vh 3vw;
  text-transform: none;
  line-height: 1vh;
  display:initial;
  
}
.NavBar a:hover {
  background-color: white;
  color:rgb(187, 89, 159);
  box-shadow: 0 8px 8px -4px rgb(102, 88, 98);
  border-radius: 10px;
  
}

.App-header {
  font-family: Caveat;
  background-color: #917e9b;
  background: radial-gradient(90.63% 90.63% at 15.63% 26.25%, #332FDE  0%,  #4b064a 100%);
  font-size: 8vi;
  font-weight: bold;
  background-size: 250% 100%;
  animation: colorAnimate 5s ease infinite;
  animation-direction: alternate; 
}
@keyframes colorAnimate {
  0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
.App-main{
  width: 45vw;
  min-height: auto;
  font-family: 'Red Hat Text', sans-serif;
  border-radius: 25px;
  color: rgb(117, 72, 104);
  font-weight: medium;
  font-size: calc(.7em + 1.3vmin);
  box-shadow: 17px 22px #322fde88;
  background-color: #ffffff;
  margin-right: 5vw;
  margin-left: auto;
  padding: 5vh;
  margin-bottom: 5vh;
  margin-top:5vh;
  text-align: right;
}
.Photo-card{
  height: auto;
  display: flex;
  align-self: center;
  background-color: transparent !important;
  margin-left: auto;
  border-radius: 50% !important;
  box-shadow: 25px 15px #322fde88 !important;
}
.Photo-card-media{
  width: 18vw !important;
}
.Hello-world, .Cert-header{
  font-family: 'Press Start 2P', cursive;
  padding-bottom: 2vh;
  animation: fadeInRight 1s;
}
/** PROJECTS SECTION **/
.projects-header{
  font-family: 'Press Start 2P', cursive;
  text-align: center;
  display: initial;
  color:white;
  animation: fadeInLeft 2s;
  padding-bottom: 2vi;
}
.proj-Accordion{
  
  margin-top: 2vi;
}
.Proj-main, .Cert-main{
  width: auto;
  display: inline-block;
  position:relative;
  font-family: 'Red Hat Text', sans-serif;
  border-radius: 25px;
  color: rgb(117, 72, 104);
  font-weight: medium;
  font-size: calc(.7em + 1.3vmin);
  box-shadow: 17px 20px #322fde88;
  background-color: rgb(109, 19, 87);
  margin-left: 10%;
  margin-right: 10%;
  padding: 5vh;
  margin-bottom: 5vh;
  margin-top:5vh;
  text-align: center;
}

.Typo{
  
  color:rgb(187, 89, 159);
  text-align:"center";
}

/*CERTIFICATONS SECTION*/
.Cert-Description{
  display: grid;
  width: auto;
  color: #ffffff;
  font-family:"Red Hat Text, sans-serif";
  font-size: calc(.5em + 1.3vmin);
  font-weight: lighter;
  text-align: center;
  justify-items: center;
}
.Cert-header{
  color: #ffffff;
}
.Cert-items{
    width: fit-content;
    height: auto;
    column-count: 2;
    gap: 25px !important;
}
.Cert-item{
  align-self: center;
}
.Cert-image{
  border-radius: 25px;
  filter: drop-shadow(0 1rem 0.5rem rgb(59, 23, 49));
}

.ImageList-Projects{
  color: rgb(117, 72, 104);
  width: 45vw;
  height: 25vh;
  background-color: #ffffff;
}
/* FOOTER */
.App-footer{
  text-align: center;
  background-color: #4b064a;
  font-size: 1em;
  bottom:0;
  left: 0;
  right:0;
  flex: 0 0 5em;
  margin-top:auto;
  position: relative;
  text-decoration: none;
}
.App-footer a, .App-footer a:hover, .App-footer a:active, .App-footer a:visited { 
  color: white; 
}
.App-link{
  text-decoration: none;
}



/*VIEWPORTS*/
@media screen and (max-width: 915px){
  .App{
    overflow-x: visible; 
    overflow-y: scroll;
  }
  .Body{
    display: block;
  }
  .NavBar{
    font-size: 0.65em;
  }
  .App-main, .Proj-main, .Cert-main{
    width: auto;
    margin-left: 5%;
    margin-right: 5%;
    text-align: left; 
  }
  .App-main{
    font-size: large;
    margin-top: 0;
  }
  .Photo-card{
    position: relative;
    width: min-content !important;
    border-radius: 50% 50% 50% 5% !important;
    top: 4rem;
    width: 5rem;
    box-shadow:
              0px 5px 5px -3px rgba(0,0,0,0.2),
              0px 8px 10px 1px rgba(0,0,0,0.14),
              0px 3px 14px 2px rgba(0,0,0,0.12) !important;
  }
  .Photo-card-media{
    border-radius: 50% 50% 50% 5%;
  }
  .Proj-main{
    display:flex;
    flex-direction: column;
    padding: 5%;
  }

  .Cert-Description > ul{
    display: flex !important;
    flex-direction: column;
    
  }
  .Cert-image{
    width: 60vw !important;
  }
  .MuiImageListItemBar-title{
    font-size: calc(0.4em + 2vi) !important;
  }
  .Cert-items{
    width: 100%;
  }
  
  .App-header{
    padding-top: 1em;
    font-size: calc(2em + 2vi);
    text-align: center;
  }
  .App-logo{
    height: 10vi;
  }
  .App-footer{
    height: 9%;
    overflow: scroll;
  }
  .MuiImageList-root{
    grid-template-columns: repeat(1, 1fr) !important;
  }
 
}
/*CHROME */
@supports (-webkit-appearance: none) {
  .App-header {
      padding-top: 1em;;
      font-size: 100pt;
  }
  .App-logo{
    height: 10rem;
  }
  @media screen and (max-width: 915px) {
    .App-header {
      padding-top: 0.5em;;
      font-size: 2rem;
    }
    .App-logo{
      height: 1em;
    }
  }
}